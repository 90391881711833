export const LANGUAGES = [
    {
        NAME: 'languages.en',
        CODE: 'en',
        ICON: 'us',
    },
    {
        NAME: 'languages.sk',
        CODE: 'sk',
        ICON: 'sk',
    },
];

export const FALLBACK_LANGUAGE = 'sk';

export const DATE = {
    dateTimeFormats: {
        month_long: {
            month: 'long',
        },
    },
};
export const TRANSLATIONS = {
    en: {
        mapLayerControl: {
            tooltip: 'Click here to start showing map layers and activate timeline',
        },
        map: {
            layerControl: {
                objective: {
                    title: 'Objective data',
                    zelenTitle: 'Vegetation',
                    pocetnostTitle: 'Population dnesity',
                    investicieTitle: 'City investments',
                    priestupkyTitle: 'Traffic offenses',
                },
                subjective: {
                    title: 'Feelings',
                },
                tilesets: {
                    title: 'Map view',
                    default: 'Default',
                    tourist: 'Tourist',
                    ortofoto: 'Ortophoto',
                },
                subLayers: {
                    marker: 'Markers',
                    hexgrid: 'Hexagons',
                    heatmap: 'Heatmap',
                },
            },
            mocks: {
                goodSpaces: 'Good public spaces',
                badSpaces: 'Bad public spaces',
                goodVegetation: 'Quality vegetation',
                badVegetation: 'Neglected vegetation',
                goodMobility: 'Good mobility',
                badMobility: 'Mobility barriers',
                goodSecurity: 'Safe spaces',
                badSecurity: 'Unsafe spaces',
                investmentsDone: 'Implemented',
                investmentsProgress: 'In progress',
                investmentsPlanning: 'Planning',
                investments2015: 'City investments in 2015',
                investments2016: 'City investments in 2016',
                investments2017: 'City investments in 2017',
            },
        },
        menu: {
            profile: 'Profile',
            campaigns: 'Feeling maps',
            login: 'Login',
            logout: 'Logout',
        },
        network: {
            error: 'An error has occurred: {reason}',
            unknownError: 'An unexpected error has occurred',
        },
        controls: {
            searchHint: 'Search',
            zoomIn: 'Zoom in',
            zoomOut: 'Zoom out',
        },
        user: {
            successfulLogout: 'Logout successful.',
            forcedLogout: 'You have been logged out: {reason}',
        },
        profile: {
            nameLabel: 'NAME',
            emailLabel: 'E-MAIL',
            passwordLabel: 'PASSWORD',
            phoneLabel: 'PHONE',
            addressLabel: 'CITY',
            back: 'Back',
            successfullyActivated: 'Your account has been activated.',
            successfullyChanged: 'Profile changed.',
            emailChange: {
                requestProcessed: 'E-mail change has been requested.',
                emailSent: 'A verification e-mail has been sent to your inbox. You can use your old e-mail until you verify the new one.',
                loginAfterChange: 'You can log in using your new e-mail once you have verified it by clicking on the link in the email.',
            },
            accountLabel: 'ACCOUNT',
            removeAccount: 'Remove account',
        },
        profileRemoveAccount: {
            title: 'Are you sure you want to definitely remove your account, and delete all your personal information?',
            submit: 'Remove account',
            alertMessage: 'Your account has been successfully removed',
        },
        registration: {
            title: 'Welcome',
            back: 'Back',
            submit: 'Register',
            success: 'You have been registered! You now need to click on the verification link we have sent to your e-mail. After that you can log in.',
        },
        activation: {
            title: 'Account activation',
            back: 'Back',
            success: 'Your account has been activated.',
        },
        login: {
            title: 'Login',
            forgotPassword: 'Forgot your password?',
            register: 'Register',
            submit: 'CONTINUE',
            success: 'Login successful.',
        },
        forgot: {
            title: 'Forgotten password',
            instructions: 'Enter your registration e-mail. We will send you link, where you can set new password.',
            back: 'Back',
            submit: 'CONTINUE',
            success: 'An e-mail with a password reset link has been sent to your e-mail. Follow the instructions in it.',
            login: 'Login',
        },
        reset: {
            title: 'Password change',
            instructions: 'Input your new password.',
            back: 'Back',
            submit: 'CONTINUE',
            success: 'Your password has been changed.',
            login: 'Login',
        },
        feelings: {
            selectAnswerType: 'Choose answer type:',
            answerDelete: {
                title: 'Are you sure you want to delete this answer?',
                description: 'It\'s photos and comments will be deleted as well.',
                submit: 'Delete answer',
                success: 'Answer has been deleted.',
            },
            answerSaved: 'Answer has been saved.',
            marker: {
                single: 'Location',
                path: 'Path',
                polyline: 'Path',
                polygon: 'Area',
                limitReached: 'You have reached maximum number of points per answer.',
            },
            campaign: {
                endsIn: 'ends in',
                answers: 'answers:',
                categoryFilter: 'Category filter:',
                detailTitle: 'About campaign',
                start: 'BEGIN',
                noCampaignsFound: 'No campaigns found.',
                noQuestionsFound: 'No questions found for this campaign.',
                leaveTitle: 'Are you sure you want to leave the campaign?',
                leaveDescription: 'Your data wont be saved permanently.',
            },
            answerDetail: {
                title: 'Answer detail',
                gallery: 'Gallery',
            },
            attachments: {
                typeCheckFailed: 'Please, select only images.',
            },
        },
        demography: {
            aboutYou: 'Tell us something about you',
            newsletter: 'I would like to receive information about future campaigns.',
            disclaimer: 'Your age and gender won\'t be associated with your e-mail address. The address will bee used exclusively for notifications about future campaigns and news.',
            submit: 'SEND',
            success: 'Your answer has been sent. Thank you.',
        },
        form: {
            name: {
                label: 'Your name',
                change: {
                    title: 'Name change',
                    label: 'Your name',
                    submit: 'SUBMIT',
                    success: 'Name has been changed.',
                },
                presenceValidation: 'Name is mandatory',
            },
            consent: {
                label: 'I agree to privacy policy',
                presenceValidation: 'The agreement is mandatory',
            },
            password: {
                label: 'Password',
                label2: 'Confirm password',
                hint: 'Use at least 8 characters, using letters, numbers and symbols.',
                change: {
                    title: 'Password change',
                    label: 'New password',
                    label2: 'Confirm new password',
                    labelCurrent: 'Current password',
                    submit: 'SUBMIT',
                    success: 'Password has been changed.',
                },
                presenceValidation: 'Password in mandatory',
                presence2Validation: 'Confirm password is mandatory',
                presence3Validation: 'Current password is mandatory',
                matchValidation: 'Passwords must match',
            },
            email: {
                label: 'E-mail',
                change: {
                    title: 'E-mail change',
                    label: 'E-mail',
                    submit: 'SUBMIT',
                    success: 'E-mail has been changed.',
                },
                presenceValidation: 'E-mail is mandatory',
                formatValidation: 'E-mail must be properly formatted',
            },
            phone: {
                label: 'Phone number',
                change: {
                    title: 'Phone number change',
                    label: 'Your phone number',
                    submit: 'SUBMIT',
                    success: 'Phone number has been changed.',
                },
            },
            address: {
                label: 'City',
                change: {
                    title: 'City change',
                    label: 'Your city',
                    submit: 'SUBMIT',
                    success: 'City has been changed.',
                },
                presenceValidation: 'City is mandatory',
            },
            age: {
                label: 'Age',
                presenceValidation: 'Age is mandatory',
            },
            gender: {
                label: 'Gender',
                options: {
                    male: 'Male',
                    female: 'Female',
                    diverse: 'Diverse',
                },
                presenceValidation: 'Gender is mandatory',
            },
            comment: {
                label: 'Comment',
            },
            common: {
                min3CharsValidation: 'Use at least 3 \xa0characters',
                min8CharsValidation: 'Use at least 8\xa0characters',
            },
            mandatoryFields: 'Fields marked with red asterisk are mandatory.',
        },
        userLocation: {
            successful: 'Your current location has been acquired.',
            failed: 'There has been a problem acquiring your current location.',
            notSupported: 'Unfortunately, your browser does not support location services.',
        },
        wording: {
            day: {
                day: 'day',
                days234: 'days',
                days: 'days',
            },
        },
        common: {
            yes: 'Yes',
            no: 'No',
            emptyList: 'List is empty',
            continue: 'Continue',
            register: 'Register',
        },
        api: {
            error: {
                GENERAL_ERROR: 'General error.',
                UNAUTHORIZED: 'The client is not authorized to perform this action.',
                ACCESS_DENIED: 'Access is denied.',
                INVALID_CREDENTIALS: 'Invalid login credentials.',
                USER_NOT_FOUND: 'User not found.',
                RESET_REQUEST_NOT_FOUND: 'Reset password request not found.',
                CAMPAIGN_NOT_FOUND: 'Campaign not found.',
                QUESTION_NOT_FOUND: 'Question not found.',
                ANSWER_NOT_FOUND: 'Answer not found.',
                COMMENT_NOT_FOUND: 'Comment not found.',
                SELECTED_COMMENT_NOT_FOUND: 'Selected comment not found.',
                ATTACHMENT_NOT_FOUND: 'Attachment not found.',
                INVALID_VERIFICATION_ID_EMAIL: 'Invalid verification link for given e-mail.',
                INVALID_VERIFICATION_ID_PHONE: 'Invalid verification link for given phone number.',
                UPLOADING_TO_AZURE_FAILED: 'Error while uploading file into cloud storage.',
                DELETING_FROM_AZURE_FAILED: 'Error while deleting file from cloud storage.',
                VALIDATION_ERROR: 'Data entered are invalid.',
                MALFORMED_INPUT: 'Request not syntactically valid.',
                NO_USER_DATA_CHANGED: 'Nothing to do while trying to change user data.',
                PASSWORDS_DO_NOT_MATCH: 'Passwords don\'t match.',
                INVALID_PASSWORD: 'Password is invalid.',
                PASSWORDS_ARE_EQUAL: 'Old and new password cannot be equal.',
                RESET_PASSWORD_IDS_DO_NOT_MATCH: 'Invalid reset password request for given e-mail.',
                CANNOT_GENERATE_UNIQUE_ID: 'Cannot generate unique verification ID.',
                AGE_CATEGORY_NOT_FOUND: 'Age category not found.',
                CONTENT_TYPE_IS_NULL: 'Content type cannot be null.',
                UNKNOWN_VERIFICATION_ID_EMAIL: 'Unknown verification id of type: EMAIL.',
                UNKNOWN_VERIFICATION_ID_PHONE: 'Unknown verification id of type: PHONE.',
                MAX_ANSWERS_NUMBER_REACHED: 'Maximum number of answers reached.',
                CONTENT_TYPE_IS_INVALID: 'Invalid type: Only JPEG, JPG or PNG files are allowed.',
                FILE_SIZE_TOO_BIG: 'The size of the file is too big.',
                COMMENT_NOT_ALLOWED: 'Only one custom comment per answer is allowed.',
                USER_EMAIL_REGISTERED: 'User email already registered.',
                USER_EMAIL_NOT_VERIFIED: 'User email not verified, click on the link sent to your mailbox.',
                UPLOADING_TO_AZURE_UNAVAILABLE: 'Error while uploading file into cloud storage. Service is unavailable.',
                DELETING_FROM_AZURE_UNAVAILABLE: 'Error while deleting file from cloud storage. Service is unavailable.',
                UNKNOWN_ENTITY_TYPE: 'Unknown entity type.',
            },
        },
        languages: {
            en: 'English',
            sk: 'Slovak',
            current: 'English',
            icon: 'us',
        },
        pages: {
            about: {
                title: 'Our team',
                subtitle: 'Subtitle',
                titleDagmar: 'Dagmar',
                contentDagmar: 'Posledné roky venuje lepšiemu využívaniu dát vo verejnom sektore a zdravotníctve podľa princípov mydata.org. Zároveň skúma, ako cez dátovú vedu a digitálne riešenia zlepšiť starostlivosť o tehotné ženy a pacientov s dlhým covidom.',
                titleBurak: 'Michal',
                contentBurak: 'Michal od roku 2006 pôsobí ako Autorizovaný architekt SKA. V rokoch 2017 a 2004 získal Cenu Dušana Jurkoviča, v roku 2012 Cenu verejnosti CE.ZA.AR a v roku 2010 cena ITAPA 2010 za IT riešenia. Je autorom projektov East Side Architecture a Mestské zásahy Košice, Prešov, Nitra, Liptovský Mikuláš, Humenné, Trnava a ďalšie',
                titleNovacek: 'Oto',
                contentNovacek: 'Oto je architekt a urbanista, ktorý absolvoval štúdium na prestížnych domácich i zahraničných školách (STU Bratislava, HCU Hamburg, MIT Cambridge) so skúsenosťou s domácou i zahraničnou praxe so súkromnými investormi i samosprávou. Jeho expertíza sa zameriava predovšetkým na verejný priestor, participáciu a inovatívne formy regulácie za pomoci dátovej analýzy.',
            },
            home: {
                menu: {
                    howItWorks: 'How it works',
                    contact: 'Contact',
                    map: 'Map',
                    about: 'Our team',
                },
                title: 'A city is a complex organism',
                subtitle: 'To understand it, we need the help of data and artificial intelligence.',
                heading0: 'Our vision',
                content0: '<p>In the future, urban planning will not be influenced by individual biases. Rather it will be automated and will take into account opinions and feelings of all citizens. The cities will be able to respond to current and future needs of their citizens. However, we need also your help on our path.</p>',
                heading1: 'Become a member of an exciting community',
                content1: '<p>Contribute with your data and feelings to our vision. Log in to our platform and start creating your feelings about the city. You will be able to use the same login and profile also for a participatory budget in your city.</p>',
                heading2: 'Mapping on the go',
                content2: '<p>Expressing your feelings or your opinion on public spaces is easy – you can do it at home or during a walk through the city on your mobile device. We support not only citizens but also urban planners who are willing to help the city with mapping the state of public spaces and infrastructure.</p>',
                heading3: 'The outcome of the joint community effort is the feeling map',
                content3: '<p><a href="http://www.pocitovemapy.sk" target="_blank">The feeling map</a> is a tool for a participatory approach to urban planning. It offers citizens a possibility to contribute with data collection and their opinions on public spaces. For the city stakeholders, it becomes one of the analytical inputs into investment planning and concepts for transport projects and greenery revitalization. </p>',
                heading4: 'We support cities in better decision-making based on evidence',
                content4: '<p>Our analytical platform processes urban data and crowd insights to show current problems in the city and their potential solutions. We are also working with AI tools to be able to forecast future needs of the citizens and investment and development opportunities. The ultimate goal is a sustainable and livable city.</p>',
                slovakia: 'Slovakia',
            },
        },
    },
    sk: {
        mapLayerControl: {
            tooltip: 'Ak si chcete zobraziť vrstvy a zaktívniť časovú os, zobrazte si aspoň jednu vrstvu.',
        },
        map: {
            layerControl: {
                objective: {
                    title: 'Objektívne dáta',
                    zelenTitle: 'Zelené plochy',
                    pocetnostTitle: 'Početnosť občanov',
                    investicieTitle: 'Investície mesta',
                    priestupkyTitle: 'Dopravné priestupky',
                },
                subjective: {
                    title: 'Pocity občanov',
                },
                tilesets: {
                    title: 'Mapa',
                    default: 'Základná',
                    tourist: 'Turistická',
                    ortofoto: 'Ortofoto',
                },
                subLayers: {
                    marker: 'Markery',
                    hexgrid: 'Hexagony',
                    heatmap: 'Heatmapa',
                },
            },
            mocks: {
                goodSpaces: 'Kvalitné priestory',
                badSpaces: 'Nekvalitné priestory',
                goodVegetation: 'Kvalitná zeleň',
                badVegetation: 'Nekvalitná zeleň',
                goodMobility: 'Dobrý pohyb',
                badMobility: 'Bariéry v pohybe',
                goodSecurity: 'Bezpečné priestory',
                badSecurity: 'Nebezpečné priestory',
                investmentsDone: 'Realizované',
                investmentsProgress: 'Realizuje sa',
                investmentsPlanning: 'Pripravuje sa ',
                investments2015: 'Investície mesta, rok 2015 ',
                investments2016: 'Investície mesta, rok 2016',
                investments2017: 'Investície mesta, rok 2017',
            },
        },
        menu: {
            profile: 'Profil',
            campaigns: 'Pocitové mapy',
            login: 'Prihlásiť',
            logout: 'Odhlásiť',
        },
        network: {
            error: 'Nastala chyba: {reason}',
            unknownError: 'Nastala nečakaná chyba.',
        },
        controls: {
            searchHint: 'Vyhľadávanie',
            zoomIn: 'Priblížiť',
            zoomOut: 'Vzdialiť',
        },
        user: {
            successfulLogout: 'Boli ste úspešne odhlásený.',
            forcedLogout: 'Boli ste odhlásený: {reason}',
        },
        profile: {
            nameLabel: 'MENO',
            emailLabel: 'E-MAIL',
            passwordLabel: 'HESLO',
            phoneLabel: 'TELEFÓN',
            addressLabel: 'MESTO',
            back: 'Naspäť',
            successfullyActivated: 'Váš účet bol úspešne aktivovaný.',
            successfullyChanged: 'Profil úspešne zmenený.',
            emailChange: {
                requestProcessed: 'Požiadavka na\xa0zmenu e-mailu bola úspešne zaznamenaná.',
                emailSent: 'Na\xa0Vami zadaný e-mail sme poslali odkaz pre\xa0jeho overenie. Kým ho nekliknete, ostáva v\xa0platnosti Váš starý e-mail.',
                loginAfterChange: 'Po\xa0úspešnom overení sa\xa0už budete prihlasovať pomocou nového e-mailu.',
            },
            accountLabel: 'KONTO',
            removeAccount: 'Vymazať konto',
        },
        profileRemoveAccount: {
            title: 'Naozaj si želáte zrušiť konto a vymazať tak všetky Vaše osobné údaje?',
            submit: 'Zmazať',
            alertMessage: 'Vaše konto bolo úspešne vymazané.',
        },
        registration: {
            title: 'Vitajte',
            back: 'Naspäť',
            submit: 'Vytvoriť účet',
            success: 'Boli ste úspešne zaregistrovaný! Neostáva Vám už nič iné, len kliknúť na\xa0odkaz ktorý sme Vám zaslali na\xa0Váš e-mail a\xa0následne sa\xa0môžete prihlásiť.',
        },
        activation: {
            title: 'Aktivácia účtu',
            back: 'Naspäť',
            success: 'Váš účet bol úspešne aktivovaný.',
        },
        login: {
            title: 'Prihlásenie',
            forgotPassword: 'Zabudli ste Vaše heslo?',
            register: 'Vytvoriť účet',
            submit: 'ĎALEJ',
            success: 'Boli ste úspešne prihlásený.',
        },
        forgot: {
            title: 'Zabudnuté heslo',
            instructions: 'Zadajte Váš registračný e-mail, kam Vám následne zašleme odkaz, na\xa0ktorom si\xa0budete môcť vytvoriť nové heslo.',
            back: 'Naspäť',
            submit: 'ĎALEJ',
            success: 'Na\xa0Vami\xa0zadaný e-mail sme\xa0zaslali odkaz pre\xa0vytvorenie nového hesla. Nasledujte inštrukcie v\xa0správe.',
            login: 'Prihlásenie',
        },
        reset: {
            title: 'Nastavenie hesla',
            instructions: 'Zadajte vaše nové heslo.',
            back: 'Naspäť',
            submit: 'ĎALEJ',
            success: 'Vaše heslo bolo úspešne zmenené.',
            login: 'Prihlásenie',
        },
        feelings: {
            selectAnswerType: 'Zadajte miesto:',
            answerDelete: {
                title: 'Skutočne chcete vymazať túto odpoveď?',
                description: 'Spolu s\xa0ňou vymažete aj jej fotky a\xa0komentáre.',
                submit: 'Vymazať odpoveď',
                success: 'Odpoveď bola vymazaná.',
            },
            answerSaved: 'Vaša odpoveď bola uložená.',
            marker: {
                single: 'Bod',
                path: 'Trasu',
                polyline: 'Trasu',
                polygon: 'Plochu',
                limitReached: 'Dosiahli ste maximálny možný počet zadaných bodov na\xa0jednu odpoveď.',
            },
            campaign: {
                endsIn: 'končí o',
                answers: 'odpovede:',
                categoryFilter: 'Filter kategórií:',
                detailTitle: 'O kampani',
                start: 'ZAČAŤ',
                noCampaignsFound: 'Nenašli sa žiaľ žiadne kampane.',
                noQuestionsFound: 'Nenašli sa žiaľ žiadne otázky ku\xa0danej kampani.',
                leaveTitle: 'Si si istý že chceš odísť z kampane?',
                leaveDescription: 'Tvoje dáta nebudú permanentne uložené.',
            },
            answerDetail: {
                title: 'Detail odpovede',
                gallery: 'Fotogaléria',
            },
            attachments: {
                typeCheckFailed: 'Prosím vložte iba obrázky.',
            },
        },
        demography: {
            aboutYou: 'Povedz nám niečo o\xa0sebe',
            newsletter: 'Súhlasím so\xa0zasielaním informácií o\xa0ďalších kampaniach.',
            disclaimer: 'Tvoj vek a\xa0pohlavie nebudú spojené s\xa0Tvojou e-mailovou adresou. Tá bude použitá výhradne pre\xa0oznámenia ohľadom nových kampaní a\u00A0noviniek.',
            submit: 'ODOSLAŤ',
            success: 'Vaša odpoveď bola úspešne odoslaná. Ďakujeme.',
        },
        form: {
            name: {
                label: 'Vaše meno',
                change: {
                    title: 'Zmena mena',
                    label: 'Vaše meno',
                    submit: 'ODOSLAŤ',
                    success: 'Meno bolo úspešne zmenené.',
                },
                presenceValidation: 'Meno je povinné',
            },
            consent: {
                label: 'Súhlasím so spracovaním osobných údajov',
                presenceValidation: 'Súhlas je povinný',
            },
            password: {
                label: 'Heslo',
                label2: 'Potvrdenie hesla',
                hint: 'Použite 8 a\xa0viac znakov v\xa0kombinácii písmen, čísel a\xa0symbolov.',
                change: {
                    title: 'Zmena hesla',
                    label: 'Nové heslo',
                    label2: 'Potvrdiť nové heslo',
                    labelCurrent: 'Aktuálne heslo',
                    submit: 'ODOSLAŤ',
                    success: 'Heslo bolo úspešne zmenené.',
                },
                presenceValidation: 'Heslo je povinné',
                presence2Validation: 'Potvrdenie hesla je povinné',
                presence3Validation: 'Aktuálne heslo je povinné',
                matchValidation: 'Heslá sa\xa0musia zhodovať',
            },
            email: {
                label: 'E-mail',
                change: {
                    title: 'Zmena e-mailu',
                    label: 'E-mail',
                    submit: 'ODOSLAŤ',
                    success: 'E-mail bol úspešne zmenený.',
                },
                presenceValidation: 'E-mail je povinný',
                formatValidation: 'E-mail musí byť v\xa0správnom tvare',
            },
            phone: {
                label: 'Telefón',
                change: {
                    title: 'Zmena telefónneho čísla',
                    label: 'Vaše telefónne číslo',
                    submit: 'ODOSLAŤ',
                    success: 'Telefónne číslo bolo úspešne zmenené.',
                },
            },
            address: {
                label: 'Mesto',
                change: {
                    title: 'Zmena mesta',
                    label: 'Vaše mesto',
                    submit: 'ODOSLAŤ',
                    success: 'Mesto bolo úspešne zmenené.',
                },
                presenceValidation: 'Mesto je povinné',
            },
            age: {
                label: 'Vek',
                presenceValidation: 'Vek je povinný',
            },
            gender: {
                label: 'Pohlavie',
                options: {
                    male: 'Muž',
                    female: 'Žena',
                    diverse: 'Iné',
                },
                presenceValidation: 'Pohlavie je povinné',
            },
            comment: {
                label: 'Komentár',
            },
            common: {
                min3CharsValidation: 'Použite minimálne 3\xa0znaky',
                min8CharsValidation: 'Použite minimálne 8\xa0znakov',
            },
            mandatoryFields: 'Polia označené červenou hviezdičkou sú povinné.',
        },
        userLocation: {
            successful: 'Poloha úspešne zameraná.',
            failed: 'Nastal problém so\xa0zameriavaním vašej polohy.',
            notSupported: 'Váš prehliadač bohužiaľ nepodporuje funkciu získavania polohy.',
        },
        wording: {
            day: {
                day: 'deň',
                days234: 'dni',
                days: 'dní',
            },
        },
        common: {
            yes: 'Áno',
            no: 'Nie',
            emptyList: 'Zoznam je prázdny',
            continue: 'Pokračovať',
            register: 'Zaregistrovať',
        },
        api: {
            error: {
                GENERAL_ERROR: 'Nešpecifikovaná chyba.',
                UNAUTHORIZED: 'Používateľ nie je oprávnený vykonať túto operáciu.',
                ACCESS_DENIED: 'Prístup je zamietnutý.',
                INVALID_CREDENTIALS: 'Nesprávne prihlasovacie údaje.',
                USER_NOT_FOUND: 'Používateľ nebol nájdený.',
                RESET_REQUEST_NOT_FOUND: 'Žiadosť o obnovenie hesla sa nenašla.',
                CAMPAIGN_NOT_FOUND: 'Kampaň sa nenašla.',
                QUESTION_NOT_FOUND: 'Otázka sa nenašla.',
                ANSWER_NOT_FOUND: 'Odpoveď sa nenašla.',
                COMMENT_NOT_FOUND: 'Komentár sa nenašiel.',
                SELECTED_COMMENT_NOT_FOUND: 'Vybraný komentár sa nenašiel.',
                ATTACHMENT_NOT_FOUND: 'Príloha sa nenašla.',
                INVALID_VERIFICATION_ID_EMAIL: 'Neplatný overovací odkaz pre daný e-mail.',
                INVALID_VERIFICATION_ID_PHONE: 'Neplatný overovací odkaz pre dané telefónne číslo.',
                UPLOADING_TO_AZURE_FAILED: 'Nepodarilo sa uložiť súbor v cloudovom úložisku.',
                DELETING_FROM_AZURE_FAILED: 'Nepodarilo sa odstrániť súbor z cloudového úložiska.',
                VALIDATION_ERROR: 'Zadané údaje su neplatné.',
                MALFORMED_INPUT: 'Požiadavka nie je syntakticky správna.',
                NO_USER_DATA_CHANGED: 'Nedošlo k žiadnej zmene používateľových údajov.',
                PASSWORDS_DO_NOT_MATCH: 'Heslá sa nezhodujú.',
                INVALID_PASSWORD: 'Heslo je neplatné.',
                PASSWORDS_ARE_EQUAL: 'Nové heslo sa nemôže zhodovať so starým.',
                RESET_PASSWORD_IDS_DO_NOT_MATCH: 'Neplatná žiadosť o obnovenie hesla pre daný e-mail.',
                CANNOT_GENERATE_UNIQUE_ID: 'Nepodarilo sa vygenerovať jedinečné overovacie ID.',
                AGE_CATEGORY_NOT_FOUND: 'Veková kategória sa nenašla.',
                CONTENT_TYPE_IS_NULL: 'Nebol špecifikovaný typ obsahu.',
                UNKNOWN_VERIFICATION_ID_EMAIL: 'Overovací odkaz pre e-mail nebol nájdený.',
                UNKNOWN_VERIFICATION_ID_PHONE: 'Overovací odkaz pre telefónne číslo nebol nájdený.',
                MAX_ANSWERS_NUMBER_REACHED: 'Dosiahli ste maximálny možný počet odpovedí.',
                CONTENT_TYPE_IS_INVALID: 'Neplatný typ: Sú povolené iba súbory typu JPEG, JPG alebo PNG.',
                FILE_SIZE_TOO_BIG: 'Súbor je príliš veľký.',
                COMMENT_NOT_ALLOWED: 'Pre odpoveď je povolený iba jeden vlastný komentár.',
                USER_EMAIL_REGISTERED: 'Používateľ s danou e-mailovou adresou už existuje.',
                USER_EMAIL_NOT_VERIFIED: 'Používateľova e-mailová adresa nebola overená. Kliknite na linku ktorú sme Vám poslali.',
                UPLOADING_TO_AZURE_UNAVAILABLE: 'Chyba pri nahrávaní súboru na server. Služba je nedostupná.',
                DELETING_FROM_AZURE_UNAVAILABLE: 'Chyba pri mazaní súboru zo servera. Služba je nedostupná.',
                UNKNOWN_ENTITY_TYPE: 'Neznámy typ entity.',
            },
        },
        languages: {
            en: 'Angličtina',
            sk: 'Slovenčina',
            current: 'Slovenčina',
            icon: 'sk',
        },
        pages: {
            about: {
                title: 'Náš tím',
                subtitle: 'Podnadpis',
                titleDagmar: 'Dagmar',
                contentDagmar: 'Posledné roky venuje lepšiemu využívaniu dát vo verejnom sektore a zdravotníctve podľa princípov mydata.org. Zároveň skúma, ako cez dátovú vedu a digitálne riešenia zlepšiť starostlivosť o tehotné ženy a pacientov s dlhým covidom.',
                titleBurak: 'Michal',
                contentBurak: 'Michal od roku 2006 pôsobí ako Autorizovaný architekt SKA. V rokoch 2017 a 2004 získal Cenu Dušana Jurkoviča, v roku 2012 Cenu verejnosti CE.ZA.AR a v roku 2010 cena ITAPA 2010 za IT riešenia. Je autorom projektov East Side Architecture a Mestské zásahy Košice, Prešov, Nitra, Liptovský Mikuláš, Humenné, Trnava a ďalšie',
                titleNovacek: 'Oto',
                contentNovacek: 'Oto je architekt a urbanista, ktorý absolvoval štúdium na prestížnych domácich i zahraničných školách (STU Bratislava, HCU Hamburg, MIT Cambridge) so skúsenosťou s domácou i zahraničnou praxe so súkromnými investormi i samosprávou. Jeho expertíza sa zameriava predovšetkým na verejný priestor, participáciu a inovatívne formy regulácie za pomoci dátovej analýzy.',
            },
            home: {
                menu: {
                    howItWorks: 'Ako to funguje',
                    contact: 'Kontakt',
                    map: 'Mapa',
                    about: 'Náš tím',
                },
                title: 'Mesto je zložitý organizmus',
                subtitle: 'Aby sme mu porozumeli, potrebujeme si pomôcť dátami a umelou inteligenciou.',
                heading0: 'Naša vízia',
                content0: '<p>V budúcnosti nebude územné plánovanie ovplyvnené subjektívnymi názormi jednotlivcov, ale bude automatizované a zohľadní názory a pocity všetkých občanov. Mestá tak budú vedieť reagovať na aktuálne aj budúce potreby svojich obyvateľov. Na tejto ceste pokroku Však potrebujeme aj Vašu pomoc.</p>',
                heading1: 'Staňte sa súčasťou komunity',
                content1: '<p>Prispejte svojimi dátami a pocitmi k napĺňaniu našej vízie. Prihláste sa na našu platformu a začnite pridávať svoje pocity o meste. Rovnaké prihlasovacie údaje a profil budete vedieť využiť aj pre participatívny rozpočet vo Vašom meste.</p>',
                heading2: 'Mapovanie priamo v teréne',
                content2: '<p>Vyjadriť svoj pocit alebo názor na dané mesto je jednoduché – či už z pohodlia domova alebo počas prechádzky mestom na mobilnom zariadení. Podporujeme v mapovaní nielen občanov, ale aj odbornú verejnosť, takzvaných pasportérov, ktorí pomôžu mestu zmapovať napríklad stav verejných priestorov a mestského mobiliáru.</p>',
                heading3: 'Výsledkom snaženia celej komunity je Pocitová mapa',
                content3: '<p><a href="http://www.pocitovemapy.sk" target="_blank">Pocitová mapa</a> je nástrojom participatívnej tvorby verejného priestoru, ktorý ponúka možnosť aktívneho zapojenia občanov do zberu informácií a názorov na verejné priestory. Pre zamestnancov mesta predstavuje jeden z podporných podkladov pre plánovanie investičných projektov, vytváranie koncepcií dopravy, či zelene.</p>',
                heading4: 'Podporujeme mestá v lepšom rozhodovaní na základe dôkazov',
                content4: '<p>Naša analytická platforma spracováva mestské dáta a múdrosť davu s cieľom ukázať aktuálne problémy mesta a ich možné riešenia. Pracujeme tiež s nástrojmi umelej inteligencie, aby sme v budúcnosti vedeli predpovedať potreby občanov a vedeli odhadnúť návratnosť a účelnosť investícií.</p>',
                slovakia: 'Slovensko',
            },
        },
    },
};
